body{
    background: #e9ecef;
    color: #8898aa;
    cursor: not-allowed;
}

body[contenteditable=true]{
    background: white;
    color: initial;
    cursor: initial;
}
figure{
    margin: 0;
}
.only_mobile{
    position: relative;
    pointer-events: none;
    user-select: none;
	&:before{
        content:'Only Mobile';
        position: absolute;
        top: 0;
        left: 0;
        font-size: 12px;
        font-weight: bold;
        padding: 4px 12px;
        background: #5e72e4;
        color: white;
        border-bottom-right-radius: 5px;
    }
}
.only_desktop{
    position: relative;
    pointer-events: none;
    user-select: none;
	&:before{
        content:'Only Desktop';
        position: absolute;
        top: 0;
        left: 0;
        font-size: 12px;
        font-weight: bold;
        padding: 4px 12px;
        background: #5e72e4;
        color: white;
        border-bottom-right-radius: 5px;
    }
}
.heading{
    position: relative;
    pointer-events: none;
    user-select: none;
    &:before{
        content: "Heading";
        position: absolute;
        top: -6px;
        left: -12px;
        font-size: 9px;
        font-weight: bold;
        padding: 1px 4px 1px 8px;
        background: red;
        text-transform: lowercase;
        color: white;
        border-radius: 0px;
    }
}